<template>
  <v-dialog :value="dialog" persistent max-width="1200" @click:outside="$emit('close')" >
    <v-card>
      <v-card-title class="text-h5"> Settings </v-card-title>
      <v-row class="AIChatSettings__header">
        <h4>Chat Model</h4>
      </v-row>
      <v-row class="AIChatSettings__toolSet_dropdown">
        <v-col>
          <v-autocomplete
              class="mb-3"
              style="width: 250px"
              outlined
              dense
              label="AI Model"
              :items="models"
              v-model="selectedModel"
              item-text="label"
              item-value="value"
              @change="(value) => updateSettings({ model: value })"
          ></v-autocomplete>
        </v-col>
      </v-row>
     <v-row class="AIChatSettings__header">
        <h4>Tool Set</h4>
     </v-row>
      <v-row class="AIChatSettings__toolSet_dropdown">
        <v-col>
          <v-autocomplete
              class="mb-3"
              outlined
              dense
              label="Tool Set"
              :items="toolSets"
              v-model="selectedToolSet"
              item-text="name"
              item-value="id"
              @change="(value) => selectToolSet(value)"
              :append-outer-icon="toolSetsLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
              @click:append-outer="this.getToolSets"
              clearable
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-btn @click.stop="isCreateToolSetOpen = true" class="AIChatSettings__sendButton">
            Create New
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="selectedToolSetEntity" class="ml-8">
        <v-col>
          <v-row class="mb-3 align-center">
            <h4>Tools</h4>
            <v-btn style="" icon @click.stop="setEditToolSet({...selectedToolSet}); isEditToolSetOpen = true">
              <v-icon small> mdi-pencil-outline </v-icon>
            </v-btn>
          </v-row>
          <li v-for="tool in selectedToolSetEntity.tools" :key="tool.id" class="AIChatSettings__tools_list">
            <lu>
              {{ tool.name }};
            </lu>
          </li>
        </v-col>
      </v-row>
      <v-row class="AIChatSettings__header">
          <h4>System Prompt</h4>
      </v-row>
      <v-row class="AIChatSettings__systemPrompt">
        <v-col>
          <v-textarea
              class="TextArea"
              outlined
              dense
              :placeholder="'System Prompt'"
              hide-details="auto"
              novalidate
              :rows="5"
              v-model="systemPromptText"
          >
          </v-textarea>
        </v-col>
        <v-col>
          <v-row>
          <v-autocomplete
              class="mb-3 mt-3"
              outlined
              dense
              clearable
              label="System Prompts"
              :items="systemPrompts"
              v-model="selectedSystemPrompt"
              item-text="name"
              item-value="id"
              @change="(value) => updateSelectedSystemPrompt(value)"
              :append-outer-icon="systemPromptLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
              @click:append-outer="this.getSystemPrompts"
          ></v-autocomplete>
          </v-row>
          <v-row>
            <v-btn @click.stop="saveSystemPrompt()" class="AIChatSettings__sendButton" :loading="updateSystemPromptLoading">
              Save
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="AIChatSettings__header">
        <h4>Additional</h4>
      </v-row>
      <v-row class="AIChatSettings__systemPrompt">
        <v-checkbox
            class="mb-3 mt-3"
            type="checkbox"
            id="checkbox"
            v-model="hideToolIO"
            label="Hide Tool Input/Output"
            @change="updateAdditional()"
        ></v-checkbox>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Close </v-btn>
      </v-card-actions>
    </v-card>
    <create-tool-set :dialog="isCreateToolSetOpen" @close="isCreateToolSetOpen = false"></create-tool-set>
    <edit-tool-set :dialog="isEditToolSetOpen" @close="isEditToolSetOpen = false; selectedToolSet = currentToolSet; selectedToolSetEntity = currentToolSet "></edit-tool-set>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import CreateToolSet from "@/components/modals/tool-set/create-tool-set/create-tool-set.vue";
import EditToolSet from "@/components/modals/tool-set/edit-tool-set/edit-tool-set.vue";
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
  mapMutations: AIChatMutations,
} = createNamespacedHelpers('AIChat');

export default {
  name: 'AIChatSettings',
  components: {CreateToolSet, EditToolSet},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCreateToolSetOpen: false,
      isEditToolSetOpen: false,
      systemPromptText: '',
      selectedToolSet: null,
      selectedToolSetEntity: null,
      selectedSystemPrompt: null,
      hideToolIO: null,
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude V3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V2',
          value: 'anthropic.claude-v2:1'
        }
      ],
      selectedModel: {
        label: 'Claude V3 Haiku',
        value: 'anthropic.claude-3-haiku-20240307-v1:0'
      },
    };
  },
  computed: {
    ...AIChatGetters({
      toolSets: 'TOOL_SETS',
      toolSetsLoading: 'TOOL_SETS_LOADING',
      userSettings: 'USER_SETTINGS',
      systemPrompts: 'SYSTEM_PROMPTS',
      systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
      updateSystemPromptLoading: 'UPDATE_SYSTEM_PROMPT_LOADING',
      currentToolSet: 'CURRENT_TOOL_SETS'
    })
  },
  methods: {
    ...AIChatActions(['updateUserSettings', 'getToolSets', 'getSystemPrompts', 'updateSystemPrompt', 'createSystemPrompt']),
    ...AIChatMutations({
      setSystemPrompts: 'SET_SYSTEM_PROMPTS',
      setEditToolSet: 'SET_EDIT_TOOL_SET'
    }),
    async updateSettings(data) {
      await this.updateUserSettings(data)
    },
    async selectToolSet(value) {
      this.selectedToolSetEntity = this.toolSets.find((s) => s.id === value);
      await this.updateSettings({ toolSetId: value });
    },
    async saveSystemPrompt() {
      if(this.selectedSystemPrompt?.id) {
        await this.updateSystemPrompt({
          systemPromptId: this.selectedSystemPrompt.id,
          prompt: this.systemPromptText,
        });
      }
      const prompt = await this.createSystemPrompt({
        prompt: this.systemPromptText,
        name: this.systemPromptText,
      })
      this.systemPrompts.push(prompt);
      this.selectedSystemPrompt = prompt;
      this.systemPromptText = prompt.prompt;
      await this.updateSettings({ systemPromptId: prompt.id });
    },
    async updateSelectedSystemPrompt(value) {
      const systemPrompt = this.systemPrompts.find((prompt) => prompt.id === value);
      await this.updateSettings({ systemPromptId: systemPrompt.id });
      this.systemPromptText = systemPrompt.prompt;
      this.selectedSystemPrompt = systemPrompt;
    },
    async updateAdditional() {
      await this.updateSettings({ hideToolIO: this.hideToolIO });
    }
  },
  async created() {
    await this.getSystemPrompts();
    await this.getToolSets();
    this.selectedToolSet = this.toolSets.find((set) => set.id === this.userSettings.toolSetId);
    this.selectedToolSetEntity = this.toolSets.find((set) => set.id === this.userSettings.toolSetId);
    const systemPrompt = this.systemPrompts.find((prompt) => prompt.id === this.userSettings.systemPromptId);
    this.selectedSystemPrompt = systemPrompt;
    this.systemPromptText = systemPrompt?.prompt;
    this.hideToolIO = this.userSettings.hideToolIO;
    this.selectedModel = this.models.find((model) => model.value === this.userSettings.model)
  }
};
</script>

<style lang="scss" scoped>
@import './ai-chat-settings';
</style>
