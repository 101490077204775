<template>
  <div class="Chat">
      <v-row>
        <v-col class="Chat__mainContainer">
          <v-row class="Chat__chat">
            <v-col v-if="!currentSessionLoading" ref="chat" id="chat" class="pl-1 pt-5">
              <div v-for="item in currentSession?.Messages || []" :key="item.id" class="Chat__message" >
                <v-row class="Chat__messageContentContainer" v-if="!(userSettings?.hideToolIO && item.type ==='tool_result')">
                  <v-col v-if="item.role ==='assistant'" class="Chat__messageIcon">
                    <img
                        :src="`${publicPath}/icons/ai_response.svg`"
                        alt=""
                        height="20"
                        style="vertical-align: middle"
                    />
                  </v-col>
                  <v-col class="Chat_messageContent">
                    <v-row v-for="(content, index) in item.content || []" :key="item.id+index" class="Chat__message">
                      <v-col>
                        <v-row  v-if="item.role === 'assistant' && !(userSettings?.hideToolIO && content.type ==='tool_use')" :class="'Chat__messageBot'" align-content="end">
                          <div :class="botMessageStyleMapping[content.type]">
                            <span style="white-space: pre-line" v-if="content.type ==='text'">{{content.text?.replaceAll(paperPattern, 'DOCUMENT')}}</span>
                            <v-row v-if="content.type ==='tool_use'">
                              <v-col align-content="center" style="align-self: center">
                                <span style="font-weight: bold">Tool Input: </span>
                              </v-col>
                              <json-viewer :value="content.input" style="white-space: pre-line; background: transparent"></json-viewer>
                            </v-row>
                          </div>

                          <div class="Chat__messageToolbar" v-if="content.type ==='text'">
                            <v-btn icon @click.stop="copy(content.text)" v-if="item?.role === 'assistant'">
                              <v-icon small> mdi-content-copy </v-icon>
                            </v-btn>
                          </div>
                        </v-row>

                        <v-row v-if="item.role === 'user' && !(userSettings?.hideToolIO && content.type ==='tool_result')" :class="'Chat__messageUser'" :align-content="content.type ==='tool_result' ? 'center' : 'end'">
                          <div :class="userMessageStyleMapping[content.type]">
                            <span style="white-space: pre-line" v-if="content.type ==='text' && !content.text.includes('<paper>')">{{content.text}}</span>
                            <span v-if="content.type ==='text' && content.text.includes('<paper>')">Document:</span>
                            <div style="max-height: 70px; overflow: auto; text-overflow: ellipsis;" v-if="content.type ==='text' && content.text.includes('<paper>')">
                              {{content.text}}
                            </div>
                            <span style="white-space: pre-line" v-if="content.type ==='image'"><v-img :width="600" style="border-radius: 15px;" :src="'data:image/png;base64,'+content?.source?.data"></v-img></span>
                            <v-row v-if="content.type ==='tool_result'">
                              <v-col align-content="center" style="align-self: center">
                                <span style="font-weight: bold">Tool Result: </span>
                              </v-col>
                              <json-viewer :value="typeof content.content === 'string' && isJSONString(content.content) ? JSON.parse(content.content) : content.content" style="white-space: pre-line; background: transparent"></json-viewer>
                            </v-row>
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="item.role ==='user'" class="Chat__messageIcon">
                    <component :is="`style`">
                      #avatar_{{ item.User.email.split('@')[0] }} {background: {{stringToColour(item.User.email.split('@')[0])}};}
                    </component>
                    <div :id="`avatar_${item.User.email.split('@')[0]}`" class="Chat__userAvatar">
                        {{item.User.email.slice(0,1).toUpperCase()}}
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="Chat__message" v-if="sendMessageLoading">
                <vue-loaders-ball-beat color="gray" scale="0.5"/>
              </div>
            </v-col>
          </v-row>
          <div class="Chat__buttonSection">
          <v-row class="Chat__messageInput">
            <v-col>
                <v-textarea
                    class="TextArea"
                    outlined
                    dense
                    :placeholder="'Message'"
                    hide-details="auto"
                    novalidate
                    :rows="5"
                    v-model="message"
                    id="message_input"
                    @keydown.enter.prevent=""
                >
                </v-textarea>
            </v-col>
            <v-col style="max-width: 3%">
              <v-row>
                <v-btn icon @click.stop="send()" class="Chat__send_button">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </v-row>
              <v-row>
                <v-file-input
                    label="File input"
                    hide-input
                    v-model="files"
                    class="Chat__attach_button"
                    multiple
                    accept=".doc,.docx,.pdf,.csv,.txt,.html,.odt,.rtf,.epub,.jpeg,.png,.jpg,image/*"
                   @change="onFileChange"
                ></v-file-input>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="Chat__files">
            <v-col>
             <v-row v-for="(file, index) in filesAccumulated" :key="index" >
                <v-col class="Chat__file">
                  <span style="font-weight: bold">{{file.name}}</span>
                </v-col>
               <v-col class="Chat__file_action">
                <v-btn icon @click.stop="onFileDelete(index)">
                  <v-icon color="red"> mdi-delete </v-icon>
                </v-btn>
               </v-col>
             </v-row>
            </v-col>
          </v-row>
          </div>
        </v-col>
        <v-col class="Chat__sessionContainer">
          <v-row class="Chat__sessionHeader">
            <v-col>
              <v-btn color="primary" @click="selectSession(null)" style="margin-top: 7px">
                Start New Chat
              </v-btn>
            </v-col>
            <v-col class="Chat__settings">
              <v-btn icon @click.stop="isSettingsOpen=true">
                <v-icon> mdi-settings </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="pl-4 mt-0 mb-2">
            <h2>Recent:</h2>
          </v-row>
          <v-tabs
              v-model="selectedTab"
              @change="(v) => this.fetchSessions(v)"
              class='mb-5'
          >
            <v-tab
                v-for="tab in tabs"
                :key="tab.name"
                :disabled="tab.disabled">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
          <div v-if="!sessionsListLoading">
          <v-row
              :class="session?.id === currentSession?.id ? 'Chat__sessions__selected' : 'Chat__sessions'"
              v-for="(session) in sessionsList"
              :key="session?.id"
              @click="selectSession(session)"
              v-on:dblclick="setEditSession({...session}); isEditSessionOpen = true"
          >
            <v-col>
              <v-row style=" display: flex;">
                <p class="Chat__text">
                  {{session?.name}}
                </p>
                <v-btn v-if="session?.id === currentSession?.id" style="height: 0; width: 0; margin-top: 10px;" icon @click.stop="setEditSession({...session}); isEditSessionOpen = true">
                  <v-icon :color="'white'" small> mdi-pencil-outline </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
          </div>
          <v-row v-if="sessionsListLoading">
            <vue-loaders-ball-spin-fade-loader color="gray" scale="0.5"/>
          </v-row>
          <v-btn class="textButton" @click.stop="loadMoreSessions()" :loading="sessionsListLoading" v-if="canLoadMoreSession">
            Load more
          </v-btn>
        </v-col>
      </v-row>
      <a-i-chat-settings :dialog="isSettingsOpen" @close="isSettingsOpen = false" />
      <edit-session-name :dialog="isEditSessionOpen" @close="isEditSessionOpen = false" />
  </div>
</template>

<script>
import moment from 'moment';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import Vue from 'vue'
import {createNamespacedHelpers} from "vuex";

const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
  mapMutations: AIChatMutations,
} = createNamespacedHelpers('AIChat');
const { mapGetters: customerGetters } =
    createNamespacedHelpers('customer');

import VueLoaders from 'vue-loaders';
import 'vue-loaders/dist/vue-loaders.css';
import AIChatSettings from "@/views/ai-chat/ai-chat-settings/ai-chat-settings.vue";
import { io } from 'socket.io-client';

import JsonViewer from "vue-json-viewer";
import Cookies from "js-cookie";
import EditSessionName from "@/views/ai-chat/edit-modal/edit-session-modal.vue";
import constants from "@/util/constants";
import PromptTab from "@/views/promts/tabs/prompt-tab.vue";
import SystemPromptTab from "@/views/promts/tabs/system-prompts-tab.vue";
import ToolsTab from "@/views/promts/tabs/tools-tab.vue";

Vue.component('DynamicScroller', DynamicScroller)
Vue.component('DynamicScrollerItem', DynamicScrollerItem)
Vue.use(VueLoaders);

export default {
  name: 'AIChat',
  components: {ToolsTab, SystemPromptTab, PromptTab, EditSessionName, AIChatSettings,JsonViewer},
  data() {
    return {
      AI_CHAT_WEBSOCKET_URL: 'https://dev.shifthealth.io/ws/ai_service',
      IS_LOCAL_HOST: 'false',
      publicPath: constants.isDevelopment ? "" : "",
      editSession: null,
      isEditSessionOpen: false,
      socket: undefined,
      paperPattern: /<paper>(.*?)<\/paper>/gs,
      files: [],
      filesAccumulated: [],
      sessionPage: 0,
      messageCheckingTimer: null,
      message: '',
      isSettingsOpen: false,
      canLoadMoreSession: true,
      userMessageStyleMapping: {
        text: 'Chat__messageContentUser',
        image: 'Chat__imageMessage',
        tool_result: 'Chat__toolResultMessage',
      },
      botMessageStyleMapping: {
        text: 'Chat__messageContentBot',
        tool_use: 'Chat__toolUseMessage'
      },
      tabs: [
        {
          name: 'User'
        },
        {
          name: 'Customer'
        },
      ],
      selectedTab: Number(this.$route.query.selectedTab) || 0
    }
  },
  watch: {
    async 'selectedCustomer.customer_id'() {
      this.getSessions({ onlyUser: true, page: this.sessionPage, push: false })
      this.setSession(null);
      this.$router.push({ query: { } });
    },
  },
  computed: {
    ...AIChatGetters({
      sessionsList: 'SESSIONS_LIST',
      sessionsListLoading: 'SESSIONS_LIST_LOADING',
      toolSets: 'TOOL_SETS',
      toolSetsLoading: 'TOOL_SETS_LOADING',
      createToolSetLoading: 'CREATE_TOOL_SET_LOADING',
      currentToolSet: 'CURRENT_TOOL_SETS',
      currentToolSetLoading: 'CURRENT_TOOL_SET_LOADING',
      AIResponseLoading: 'AI_RESPONSE_LOADING',
      currentSession: 'CURRENT_SESSION',
      currentSessionLoading: 'CURRENT_SESSION_LOADING',
      userSettings: 'USER_SETTINGS',
      userSettingsLoading: 'USER_SETTINGS_LOADING',
      sendMessageLoading: 'SEND_MESSAGE_LOADING',
    }),
    ...customerGetters({
      user: 'USER',
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
  },
  methods: {
    ...AIChatActions(['createToolSet', 'getCurrentSession','getSessions', 'getToolSets', 'getUserSettings','sendMessage', 'getToolSets', 'updateUserSettings', 'getLastMessage', 'updateSession']),
    ...AIChatMutations({
      setSession: 'SET_CURRENT_SESSION',
      setSendMessageLoading: 'SET_SEND_MESSAGE_LOADING',
      setEditSession: 'SET_EDIT_SESSION',
    }),
    validateSize(input) {
      const fileSize = input.size / 1024 / 1024; // in MiB
      return fileSize > 1;
    },
    async fetchSessions(tab) {
      await this.getSessions({ onlyUser: tab === 0, page: 0, push: false })
    },
    stringToColour(str) {
      let hash = 0;
      str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash)
      })
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff
        colour += value.toString(16).padStart(2, '0')
      }
      return colour
    },
    async copy(text) {
        await navigator.clipboard.writeText(text);
    },
    isJSONString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    async loadMoreSessions() {
      this.sessionPage += 1;
      const preLoadCount = this.sessionsList.length;
      await this.getSessions({ page: this.sessionPage, push: true })
      const postLoad = this.sessionsList.length;
      if(postLoad === preLoadCount) {
        this.canLoadMoreSession = false;
      }
    },
    async selectSession(session) {
      if(this.currentSession?.id === session?.id) {
        return
      }
      if(!session) {
        this.setSession(null);
        return;
      }
      await this.getCurrentSession({ sessionId: session?.id });
      this.$router.push({ query: { session: this.currentSession.id } });
      this.scroll();
    },
    makeDisplayMessage(files) {
      const init = {
        id: Math.random(),
        content: [
          {
            text: this.message,
            type: 'text'
          }
        ],
        role: 'user',
        type: 'common',
        User: {
          email: this?.user?.email
        }
      }
      for(const file of files) {
        if(file.type.includes('image/')) {
          init.content.push({
            type: 'image',
            source: {
              "type": "base64",
              data: file.base64.replace(/^data:image\/\w+;base64,/, ''),
            }
          })
        } else {
          init.content.push({
            text: `${file.name}`,
            type: 'text',
          });
        }
      }
      return init;
    },
    async send() {
      if(this.message === '') {
        return;
      }
      const files = await this.prepareFiles();
      const displayMessage = this.makeDisplayMessage(files)
      if(!this.currentSession.id) {
        this.setSession({
          Messages: [displayMessage]
        })
      } else {
        this.currentSession.Messages.push(displayMessage)
      }

      const messageValue = this.message.valueOf();
      this.message = '';
      this.setSendMessageLoading(true);


      this.filesAccumulated = [];
      this.files = [];

      this.scroll();

      const { message, session } = await this.sendMessage({
        sessionId: this.currentSession.id,
        files: files,
        content: [{
          text: messageValue,
          type: 'text',
        }]
      });

      if(!this.currentSession?.id) {
        const sessionList = this.sessionsList;
        sessionList.pop();
        sessionList.unshift(session);
        this.sessionsList = sessionList;
        this.$router.push({ query: { session: session.id } });
      }

      if(message.type === 'common' || message.type === 'error') {
        this.setSendMessageLoading(false);
      }

      if(!this.currentSession.id) {
        this.setSession(session);
      } else {
        const sessionCopy = this.currentSession;
        const lastIndex = session.Message.length - 1;
        sessionCopy.Messages[lastIndex] = message
        this.setSession(sessionCopy)
      }
      this.scroll();
    },
    formatDate(date) {
      if (!date) return '';
      return moment(date).format(`HH:MM:SS, DD MMMM YYYY`);
    },
    onFileChange(event) {
      event.forEach(thisFile => {
        if(this.validateSize(thisFile)) {
          this.$store.dispatch('notifications/setError',`${thisFile.name} too big`)
          return;
        }
        this.filesAccumulated.push(thisFile)
      })
      this.files = [];
    },
    scroll() {
      setTimeout(() => {
        this.$refs.chat.lastElementChild.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' })
      },10)
    },
    onFileDelete(index) {
      this.filesAccumulated.splice(index, 1);
      this.files = [];
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    },
    async prepareFiles() {
      const prepared = await Promise.all(this.filesAccumulated.map(async (file) => {
        const base64 =  await this.toBase64(file)
        return {
          base64: base64,
          name: file.name,
          type: file.type
        }
      }));
      return prepared;
    },
    connectSocket() {
      const path = this.IS_LOCAL_HOST === 'true' ? {} : { path: '/ws/ai_service/socket.io' }
      this.socket = io(this.AI_CHAT_WEBSOCKET_URL, {
          auth: {
            userId: Cookies.get('userId'),
          },
          ...path
      });

      this.socket.on('chat:message_chunk', this.processMessageChunk);
    },
    processMessageChunk({ chunk_type, chunk, message, messageType }) {
      const lastIndex = this.currentSession.Messages.length - 1;
      if(chunk_type === 'new_message') {
        const newMessage = message ? message : {
          id: Math.random(),
          content: [],
          role: 'assistant',
          type: 'common',
        }
        if(newMessage.type === 'common' || newMessage.type === 'error') {
          this.setSendMessageLoading(false)
        } else {
          this.setSendMessageLoading(true)
        }
        this.currentSession.Messages.push(newMessage);
        return;
      }
      if(chunk_type === 'content_block_start') {
        let message = chunk.content_block
        if(message.type === 'tool_use') {
          message.input = ''
        }
        this.currentSession.Messages[lastIndex].content.push(message);
      }

      if(chunk_type === 'content_block_delta') {
        if(chunk.delta.type === 'input_json_delta') {
          this.currentSession.Messages[lastIndex].content[chunk.index].input += typeof chunk.delta.partial_json === 'object' ? JSON.stringify(chunk.delta.partial_json) : chunk.delta.partial_json
        }
        if(chunk.delta.type === 'text_delta') {
          this.currentSession.Messages[lastIndex].content[chunk.index].text += chunk.delta.text;
        }
      }

      this.currentSession.Messages[lastIndex].type = messageType;

      if(messageType === 'common' || messageType === 'error') {
        this.setSendMessageLoading(false)
      } else {
        this.setSendMessageLoading(true)
      }

      this.scroll();
    }
  },
  async created() {

    this.AI_CHAT_WEBSOCKET_URL = process.env.VUE_APP_AI_CHAT_WEBSOCKET_URL;
    this.IS_LOCAL_HOST = process.env.VUE_APP_AI_CHAT_IS_LOCALHOST;

    await this.getUserSettings();

    this.getSessions({ onlyUser: true, page: this.sessionPage, push: false })

    if(this.$route.query.session) {
      await this.getCurrentSession({ sessionId: this.$route.query.session });
    }

    this.scroll();

    const lastIndex = (this.currentSession?.Messages?.length || 1) - 1;
    let messageType;
    try {
      messageType = this.currentSession?.Messages[lastIndex]?.type
    } catch (e) {
      console.log(e)
    }
    if(messageType === 'common' || messageType === 'error' || !messageType) {
      this.setSendMessageLoading(false)
    }

    this.connectSocket()
  },
  beforeDestroy() {
    this.socket?.disconnect();
  },
  mounted() {
    document.getElementById('message_input').addEventListener("keydown", async (e) => {
      if (e.key == "Enter" && e.ctrlKey == false && this.message.replaceAll('\n','') !== '') {
        await this.send()
      }
      if(e.key == "Enter" && e.ctrlKey == true) {
        this.message += '\n';
        return;
      }
      if(e.key == "Enter" && e.shiftKey == true) {
        this.message += '\n';
        return;
      }
    })
    document.getElementById('message_input').onpaste = (event) => {
      const items = (event?.clipboardData || event?.originalEvent?.clipboardData).items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          this.filesAccumulated ??= [];
          this.filesAccumulated.push(blob)
        }
      }
    }
  }
};
</script>

<style lang="scss">
@import './ai-chat';
</style>
