<template>
  <v-dialog :value="dialog" persistent max-width="700">
    <v-card>
      <v-card-title class="text-h5"> Create Tool Set </v-card-title>

      <div class="px-5 my-5">
        <v-text-field
          label="Tool Set Name"
          placeholder="Tool Set Name"
          outlined
          v-model="edit.name"
        ></v-text-field>
      </div>

      <v-row class="ma-0" align="center" justify="center" v-for="item in this.edit?.tools" :key="item.id">
        <v-col class="tool_col">
          <p class="font-weight-bold ma-0">
            {{item?.name}}
          </p>
        </v-col>
        <v-col class="tool_del" >
          <v-btn width="25" icon color="error" @click.stop="deleteTool(item?.id)">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-autocomplete
          ref="toolSelect"
          class="mb-3 ml-5 mr-5 mt-5"
          outlined
          dense
          @input="(val) => setNewTool(val)"
          item-text="name"
          item-value="id"
          :items="Tools"
          label="Add Tool"
          :append-outer-icon="fetchToolsLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
          @click:append-outer="listTools"
          clearable></v-autocomplete>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="createNewSet()" :loading="createToolSetLoading"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const {
  mapActions: AIChatActions,
  mapGetters: AIChatGetters,
} = createNamespacedHelpers('AIChat');
const {  mapGetters: toolsGetters, mapActions: toolsActions } =
    createNamespacedHelpers('promptsTools');

export default {
  name: 'EditToolSet',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...toolsGetters({
      Tools: 'TOOLS',
      fetchToolsLoading: 'fetchToolsLoading',
    }),
    ...AIChatGetters({
      createToolSetLoading: 'CREATE_TOOL_SET_LOADING',
      edit: 'GET_EDIT_TOOL_SET'
    })
  },
  methods: {
    ...AIChatActions(['updateToolSet']),
    ...toolsActions(['fetchTools']),
    async listTools() {
      await this.fetchTools()
    },
    setNewTool(val) {
      const exists = this.edit?.tools?.find((tool) => tool.id === val)
      if(exists || !val) {
        return;
      }
      const tool = this.Tools?.find((tool) => tool?.id === val);
      this.edit.tools = [...this.edit?.tools, tool];
    },
    deleteTool(val) {
      const tools = this.edit?.tools?.filter((tool) => tool?.id !== val);
      this.edit.tools = [...tools];
    },
    async createNewSet() {
      await this.updateToolSet({
        toolSetIds: this.edit?.tools?.map((tool) => tool.id),
        name: this.edit?.name,
        id: this.edit?.id
      })
      this.$emit('close');
    }
  },
  async created() {
    this.edit ??= {}
    await this.fetchTools()
  }
};
</script>

<style lang="scss" scoped>
@import 'edit-tool-set';
</style>
